import React, { useState } from 'react';
import './table.css';
import * as XLSX from 'xlsx';
import { deleteDoc, doc, getDoc, updateDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { db } from '../config/firebase.config';
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.css';

const TableCategory = ({ category }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedCategoryId, setExpandedCategoryId] = useState(null);
    const rowsPerPage = 10;

    const currentRows = Array.isArray(category) ? category.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
    const totalPages = Math.ceil((Array.isArray(category) ? category.length : 0) / rowsPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

    const exportToExcel = () => {
        if (!Array.isArray(category) || category.length === 0) {
            toast.error('No data to export.');
            return;
        }
        const worksheet = XLSX.utils.json_to_sheet(category);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'category');
        XLSX.writeFile(workbook, 'categoryList.xlsx');
    };

    const deleteProducts = async (barcodes = []) => {
        if (!Array.isArray(barcodes) || barcodes.length === 0) return;

        try {
            const productsRef = collection(db, 'products');
            const q = query(productsRef, where('barcode', 'in', barcodes));
            const querySnapshot = await getDocs(q);
            const deletions = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
            await Promise.all(deletions);
            toast.success('Associated products deleted!');
        } catch (error) {
            console.error('Error deleting products:', error);
            toast.error(`Can't Execute Function: ${error.message}`);
        }
    };

    const handleDeleteCategory = async (id) => {
        try {
            const categoryRef = doc(db, 'categories', id);
            const categoryDoc = await getDoc(categoryRef);

            if (!categoryDoc.exists()) {
                toast.error('Category not found');
                return;
            }

            const categoryData = categoryDoc.data();
            const productDeletions = Array.isArray(categoryData.products)
                ? categoryData.products.map(async (product) => {
                    const q = query(collection(db, 'products'), where('sku', '==', product.sku));
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach(async (productDoc) => await deleteDoc(productDoc.ref));
                })
                : [];

            await Promise.all(productDeletions);
            await deleteDoc(categoryRef);
            toast.success('Category and its products deleted successfully!');
        } catch (error) {
            console.error('Error deleting category:', error);
            toast.error(`Can't Execute Function: ${error.message}`);
        }
    };

    const handleDeleteSubcategory = async (categoryId, subcategoryId, barcodes = []) => {
        try {
            const categoryRef = doc(db, 'categories', categoryId);
            const categoryDoc = await getDoc(categoryRef);

            if (!categoryDoc.exists()) {
                toast.error('Category not found');
                return;
            }

            const data = categoryDoc.data();
            const updatedSubCategories = Array.isArray(data.subCategories)
                ? data.subCategories.filter(sub => sub.id !== subcategoryId)
                : [];

            await updateDoc(categoryRef, { subCategories: updatedSubCategories });
            await deleteProducts(barcodes);
            toast.success('Subcategory and associated products deleted!');
        } catch (error) {
            console.error('Error deleting subcategory:', error);
            toast.error(`Can't Execute Function: ${error.message}`);
        }
    };

    const handleExpandCategory = (id) => {
        setExpandedCategoryId(expandedCategoryId === id ? null : id);
    };

    return (
        <div>
            <div className="export-div">
                <button onClick={exportToExcel}>Export to Excel</button>
            </div>

            <section className="table__body">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Category Name</th>
                            <th>No. of Products</th>
                            <th>Sub Categories</th>
                            <th>Status</th>
                            <th>More</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((order) => (
                            <React.Fragment key={order.id}>
                                <tr onClick={() => handleExpandCategory(order.id)} style={{ cursor: 'pointer' }}>
                                    <td>{order.id?.length > 5 ? `${order.id.slice(0, 5)}...` : order.id || 'N/A'}</td>
                                    <td>{order.categoryName || 'N/A'}</td>
                                    <td>{Array.isArray(order.products) ? order.products.length : 'N/A'}</td>
                                    <td>{Array.isArray(order.subCategories) ? order.subCategories.length : 'N/A'}</td>
                                    <td>{order.status || 'N/A'}</td>
                                    <td>{order.gstin?.length > 10 ? `${order.gstin.slice(0, 10)}...` : order.gstin || 'N/A'}</td>
                                    <td><div className="divContentDeleteorUpdate">
                                        <button className="deleteMd" onClick={(e) => { e.stopPropagation(); handleDeleteCategory(order.id); }}>
                                            <MdDelete />
                                        </button>
                                    </div>
                                    </td>
                                </tr>
                                {expandedCategoryId === order.id && Array.isArray(order.subCategories) && (
                                    <tr>
                                        <td colSpan="7">
                                            <table className="subCategoryTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sub Category ID</th>
                                                        <th>Sub Category Name</th>
                                                        <th>No. of Products</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order.subCategories.map((sub, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{sub.categoryName || 'N/A'}</td>
                                                            <td>{Array.isArray(sub.products) ? sub.products.length : 'N/A'}</td>
                                                            <td><div className="divContentDeleteorUpdate">
                                                                <button className="deleteMd" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleDeleteSubcategory(order.id, sub.id, sub.products?.map(p => p.barcode));
                                                                }}>
                                                                    <MdDelete />
                                                                </button>
                                                            </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </section>

            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>{"<"}</button>
                {[...Array(totalPages)].map((_, i) => (
                    <button key={i + 1} onClick={() => handlePageChange(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
                        {i + 1}
                    </button>
                ))}
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>{">"}</button>
            </div>
        </div>
    );
};

export default TableCategory;
